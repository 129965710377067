import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './home'
import setting from './setting'

Vue.use(VueRouter)
/* 配置 */
const routes = [
  /* 路由重定向 */
  {
    path: '/',
    redirect: '/mine/login'
  },
  /* 登陆模块 */
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('@/views/mine'),
    children: [{
        path: 'login',
        name: 'Login',
        component: () => import('@/views/mine/login')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/mine/register')
      }
    ]
  },
  /* 视图模块 */
  {
    path: '',
    component: () => import('@/layout'),
    children: [
      home,
      setting
    ]
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})
export default router