<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    // 跳转移动端
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)) {
      window.location = "http://m.yuxiaoting.com";
    }
  },
};
</script>
