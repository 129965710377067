const setting = {
  path: 'setting',
  component: () => import('@/views/setting'),
  children: [
    {
      path: 'userlist',
      name: 'Userlist',
      component: () => import('@/views/setting/userlist')
    },
    {
      path: 'userinfo',
      name: 'Userinfo',
      component: () => import('@/views/setting/userinfo')
    },
  ]
}
export default setting