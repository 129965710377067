const home = {
  path: 'home',
  component: () => import('@/views/home'),
  children: [{
      path: 'todolist',
      name: 'TodoList',
      component: () => import('@/views/home/todolist')
    },
    {
      path: 'goodslist',
      name: 'GoodsList',
      component: () => import('@/views/home/goodslist')
    },
  ]
}
export default home